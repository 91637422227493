import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// routes
import AllRoutes from '.';

const Routes = () => {
    return (
        <BrowserRouter>
            <AllRoutes />
            <ToastContainer />
        </BrowserRouter>
    );
};

export default Routes;
